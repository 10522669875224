import * as React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import styled from "styled-components";

// markup

const SwiperWrapper = styled.div`
  width: 100%;
  background-color: red;
  z-index: 999999;
`;
const TestS = styled.div`
  height: 100rem;
  background-color: blue;
`;
const IndexPage = () => {
  return (
    <main>
      <h1> Test</h1>
      <SwiperWrapper>
        <Swiper
          spaceBetween={50}
          slidesPerView={2.5}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <TestS></TestS>
          </SwiperSlide>
          <SwiperSlide>
            <TestS>ffff</TestS>
          </SwiperSlide>
          <SwiperSlide>
            <TestS>ffff</TestS>
          </SwiperSlide>
        </Swiper>
      </SwiperWrapper>
    </main>
  );
};

export default IndexPage;
